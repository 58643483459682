// TODO add callback hook to @kaliber/global-page-state

import { useEvent } from '/machinery/useEvent'

const state = new Map()
const listeners = new Map()

export function useGlobalPageState(key) {
  const [value, setValue] = React.useState(() => state.get(key))

  useGlobalPageStateCallback(key, setValue)

  const setGlobalValue = React.useCallback(
    x => {
      const newState = typeof x === 'function' ? x(state.get(key)) : x
      state.set(key, newState)
      const keyListeners = listeners.get(key)
      if (keyListeners) keyListeners.forEach(x => x(newState))
    },
    [key]
  )

  return [value, setGlobalValue]
}

export function useGlobalPageStateCallback(key, callback) {
  const callbackEvent = useEvent(callback)

  React.useEffect(
    () => {
      const keyListeners = listeners.get(key) || listeners.set(key, new Set()).get(key)
      keyListeners.add(callbackEvent)
      return () => { keyListeners.delete(callbackEvent) }
    },
    [key, callbackEvent]
  )
}
