import { trackInteraction } from '/machinery/tracking/pushToDataLayer'
import { useSpring, useSprings, animated, to } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { useMergeRefs } from '@floating-ui/react'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useEvent } from '/machinery/useEvent'
import { useTranslate } from '/machinery/I18n'
import { useGlobalPageState } from '/machinery/useGlobalPageState'
import { styleDoubleAsteriksString } from '/machinery/styleDoubleAsteriksString'
import { easeOut } from '/machinery/easings'

import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'
import { ContainerLg, ContainerMd } from '/features/buildingBlocks/Container'
import { ButtonGhost, ButtonLinkWhite, ButtonLinkRed } from '/features/buildingBlocks/Button'
import { ButtonIconCircle } from '/features/buildingBlocks/ButtonIconCircle'

import mediaStyles from '/cssGlobal/media.css'
import styles from './HeroHome.css'

import iconArrowDown from '/images/icons/arrow-down.raw.svg'
import iconArrowRight from '/images/icons/arrow-right.raw.svg'
import iconArrowLeft from '/images/icons/arrow-left.raw.svg'

const contentElementId = 'content'

export function HeroHome({ image, title, introduction, cards, finalCard, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const { ref: isInViewportRef, isInViewport } = useIsInViewport({ rootMargin: '-50%' })

  const [, setWhiteThumbGrabber] = useGlobalPageState('isWhite-careerCoach-thumbGrabber')
  const callbackEvent = useEvent(setWhiteThumbGrabber)

  React.useEffect(
    () => {
      if (isViewportMd) return callbackEvent(false)
      callbackEvent(isInViewport)
    },
    [isViewportMd, isInViewport, callbackEvent]
  )

  return <HeroHomeBase className={cx(styles.component, layoutClassName)} {...{ isInViewportRef, image, title, introduction, cards, finalCard, layoutClassName }} />
}

export function HeroHomeLanding({ image, title, subtitle, introduction, cards, finalCard }) {
  return <HeroHomeBase className={styles.componentLanding} {...{ image, title, subtitle, introduction, cards, finalCard }} />
}

function HeroHomeBase({ image, title, introduction, cards, finalCard, className, subtitle = undefined, isInViewportRef = undefined }) {
  const { ref: scrollProgressionRef, scale } = useScrollProgressionScalingContent()

  return (
    <section data-x='hero' className={cx(styles.componentBase, className)}>
      {image && <HeroImage layoutClassName={styles.heroImageLayout} {...{ image }} />}

      <HeroContentBorder
        href={`#${contentElementId}`}
        style={{ scale }}
        layoutClassName={styles.heroContentBorderLayout}
        {...{ title, subtitle }}
      />

      <HeroContent
        id={contentElementId}
        style={{ scale }}
        layoutClassName={styles.heroContentLayout}
        {...{ scrollProgressionRef, isInViewportRef, introduction, cards, finalCard }}
      />
    </section>
  )
}

function HeroImage({ image, layoutClassName }) {
  return (
    <div className={cx(styles.componentHeroImage, layoutClassName)}>
      <div className={styles.imageContainer}>
        <ImageCover aspectRatio={16 / 11} sizes='100vw' layoutClassName={styles.imageLayout} {...{ image }} />
      </div>
    </div>
  )
}

function HeroContentBorder({ href, title, subtitle, style, layoutClassName }) {
  const { ref, isInViewport } = useIsInViewport({ rootMargin: '-10%' })

  return (
    <div className={cx(styles.componentHeroContentBorder, layoutClassName)}>
      <ContainerLg>
        <Title {...{ title, subtitle }} />
      </ContainerLg>

      <animated.span className={styles.border} {...{ style, ref }}>
        <a data-x='scroll-to-content' className={cx(styles.anchorLink, isInViewport && styles.isInViewport)} {...{ href }}>
          <Icon icon={iconArrowDown} layoutClassName={styles.iconLayout} />
        </a>
      </animated.span>
    </div>
  )
}

function Title({ title, subtitle }) {
  const stylizedTitle = styleDoubleAsteriksString(title, styles.italic)

  return (
    <div className={styles.componentTitle}>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      <h1 dangerouslySetInnerHTML={{ __html: stylizedTitle }} className={styles.title} />
    </div>
  )
}

function HeroContent({ id, scrollProgressionRef, isInViewportRef, introduction, style, cards, finalCard, layoutClassName }) {
  return (
    <div
      ref={useMergeRefs([scrollProgressionRef, isInViewportRef])}
      className={cx(styles.componentHeroContent, layoutClassName)}
      {...{ id }}
    >
      <animated.div className={styles.contentBlock} {...{ style }}>
        <ContainerMd>
          <div className={styles.contentContainer}>
            <p className={styles.introduction}>{introduction}</p>
            <CardDeck {...{ cards, finalCard }} />
          </div>
        </ContainerMd>
      </animated.div>
    </div>
  )
}

function CardDeck({ cards, finalCard }) {
  const { __ } = useTranslate()

  const [gone] = React.useState(() => new Set())
  const [reachedEnd, setReachedEnd] = React.useState(false)

  const [springStyles, api] = useSprings(cards.length, i => ({
    to: ({ ...springValues({ i, length: cards.length }) })
  }))

  const bind = useDrag(
    ({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
      const speed = velocity[0] + velocity[1]
      const trigger = speed > 0.2
      const direction = xDir < 0
        ? -1
        : 1

      if (!down && trigger) handleGone(index)

      api.start(i => {
        if (index !== i) return

        const isGone = gone.has(index)

        const x = isGone ? (200 + window.innerWidth) * direction : down ? mx : 0
        const rotate = mx / 100 + (isGone ? direction * 10 * velocity[0] : 0)
        const scale = down ? 1.1 : 1

        return {
          x,
          rotate,
          scale,
          config: {
            friction: 50,
            tension: down
              ? 800
              : isGone
                ? 200
                : 500
          },
        }
      })

      if (!down && gone.size === cards.length) setReachedEnd(true)
    }, {
      axis: 'x',
    }
  )

  return (
    <div className={styles.componentCardDeck}>
      <ButtonIconCircle
        dataX='click-to-previous-card'
        ariaLabel={__`previous-question-aria-label`}
        onClick={handlePreviousCard}
        icon={iconArrowLeft}
        layoutClassName={styles.previousButtonLayout}
      />

      <div className={styles.cardDeck}>
        {springStyles.map(({ x, y, scale, rotate }, i) => {
          const { subject, title, buttonLabel, link, dataX } = cards[i]

          return (
            <animated.div key={i} style={{ x, y }} className={styles.deckPosition}>
              <animated.div style={{ transform: to([rotate, scale], transform) }} className={styles.card} {...bind(i)}>
                <CardContent index={cards.length - i} layoutClassName={styles.cardContentLayout} {...{ subject, title, buttonLabel, link, dataX }} />
              </animated.div>
            </animated.div>
          )
        })}

        <FinalCard
          isActive={reachedEnd}
          onDeckReset={handleDeckReset}
          layoutClassName={styles.finalCardLayout}
          {...{ finalCard }}
        />
      </div>

      <ButtonIconCircle
        dataX='click-to-next-card'
        ariaLabel={__`next-question-aria-label`}
        onClick={handleNextCard}
        icon={iconArrowRight}
        layoutClassName={styles.nextButtonLayout}
      />
    </div>
  )

  function handleGone(index) {
    gone.add(index)
    trackInteraction({
      title: 'card-deck',
      action: 'swipe',
      type: 'swiped',
      index
    })
  }

  function transform(rotation, scale) {
    return `rotateY(${rotation / 10}deg) rotateZ(${rotation}deg) scale(${scale})`
  }

  function handlePreviousCard() {
    const currentIndex = cards.length - gone.size

    trackInteraction({
      title: 'card-deck',
      action: 'click',
      type: 'clicked',
      index: currentIndex
    })

    gone.delete(currentIndex)
    api.start(i => {
      if (currentIndex !== i) return

      return {
        ...springValues({ i, length: cards.length }),
        config: { friction: 50, tension: 200 }
      }
    })

    setReachedEnd(false)
  }

  function handleNextCard() {
    if (reachedEnd) return

    const nextIndex = cards.length - gone.size - 1

    trackInteraction({
      title: 'card-deck',
      action: 'click',
      type: 'clicked',
      index: nextIndex
    })

    gone.add(nextIndex)

    const x = 200 + window.innerWidth
    const rotate = Math.random() * 20 + 10
    const scale = 1

    api.start(i => {
      if (nextIndex !== i) return

      return {
        x, rotate, scale,
        config: { friction: 50, tension: 200 }
      }
    })

    if (gone.size === cards.length) setReachedEnd(true)
  }

  function handleDeckReset() {
    gone.clear()
    api.start(i => ({ ...springValues({ i, length: cards.length }), delay: i * 50 }))
    setReachedEnd(false)
  }
}

function CardContent({ index, subject, title, buttonLabel, link, dataX, layoutClassName }) {
  const hasLongWord = title?.split(/\s+/).some(word => word?.length > 14)

  return (
    <div className={cx(styles.componentCardContent, layoutClassName)}>
      <div className={styles.cardContentTextContainer}>
        <p className={styles.cardIndexText}>{subject} #{index}</p>
        <h3 className={cx(styles.cardTitle, hasLongWord && styles.hasLongWord)}>{title}</h3>
      </div>

      <ButtonLinkRed
        href={link}
        label={buttonLabel}
        layoutClassName={styles.buttonLayout}
        {...{ dataX }}
      />
    </div>
  )
}

function FinalCard({ finalCard, isActive, onDeckReset, layoutClassName }) {
  const { __ } = useTranslate()

  const { title, label, href, dataX } = finalCard || {}

  return (
    <div data-style-context='red' className={cx(styles.componentFinalCard, isActive && styles.isActive, layoutClassName)}>
      <h3 className={styles.cardTitle}>{title}</h3>

      <div className={styles.buttonsContainer}>
        {href && <ButtonLinkWhite {...{ href, label, dataX }} />}
        <ButtonGhost label={__`start-over`} dataX='click-to-reset-cards' onClick={onDeckReset} />
      </div>
    </div>
  )
}

function springValues({ i, length }) {
  const orientations = [7, -7, 0]
  const index = length + 1 - i

  return {
    x: 0,
    y: (index % 3) * -8,
    scale: 1,
    rotate: orientations[index % 3]
  }
}

function useScrollProgressionScalingContent() {
  const [{ scale }, api] = useSpring(() => ({
    scale: 0.85,
    config: { tension: 500, friction: 35 }
  }))

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.custom(0.5) },
    onChange(progression) {
      api.start({ scale: lerp({ start: 0.85, end: 1, input: easeOut(progression) }) })
    }
  })

  return { ref, scale }
}
