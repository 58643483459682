import { LabelUnderline } from '/features/buildingBlocks/LabelUnderline'
import { Icon } from '/features/buildingBlocks/Icon'
import { Loader } from '/features/buildingBlocks/Loader'

import styles from './Button.css'

import iconArrowRight from '/images/icons/arrow-right.raw.svg'

export function Button({ onClick, dataX, label, disabled = undefined, layoutClassName = undefined }) {
  return <ButtonBase type='button' className={cx(styles.component, layoutClassName)} {...{ onClick, dataX, label, disabled }} />
}

export function ButtonRed({ onClick, dataX, label, disabled = false, layoutClassName = undefined }) {
  return <ButtonBase type='button' className={cx(styles.componentRed, layoutClassName)} {...{ onClick, dataX, label, disabled }} />
}

export function ButtonGhost({ onClick, dataX, label, disabled = undefined, layoutClassName = undefined }) {
  return <ButtonBase type='button' className={cx(styles.componentGhost, layoutClassName)} {...{ onClick, dataX, label, disabled }} />
}

export function ButtonSubmit({ dataX, label, isPending, layoutClassName = undefined }) {
  return <ButtonBase type='submit' disabled={isPending} className={cx(styles.componentSubmit, layoutClassName)} {...{ dataX, label, isPending }} />
}

export function ButtonIcon({ onClick, dataX, label, icon = undefined, disabled = undefined, layoutClassName = undefined }) {
  return <ButtonBase type='button' className={cx(styles.componentIcon, layoutClassName)} {...{ onClick, dataX, label, icon, disabled }} />
}

export function ButtonIconReversed({ onClick, dataX, label, referenceProps = undefined, icon = undefined, layoutClassName = undefined }) {
  return <ButtonBase type='button' className={cx(styles.componentIconReversed, layoutClassName)} {...{ onClick, dataX, referenceProps, label, icon }} />
}

function ButtonBase({
  type,
  dataX,
  label,
  className,
  referenceProps = undefined,
  icon = undefined,
  isPending = undefined,
  onClick = undefined,
  disabled = undefined
}) {
  return (
    <button data-x={dataX} aria-label={label} className={cx(styles.componentBase, className)} {...{ onClick, type, disabled }} {...referenceProps}>
      {label && <LabelUnderline layoutClassName={styles.labelLayout} {...{ label }} />}
      {icon && <ButtonIconBox layoutClassName={styles.iconLayout} {...{ icon }} />}
      {isPending && <Loader layoutClassName={styles.loaderLayout} />}
    </button>
  )
}

export function ButtonLinkArrow({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase icon={iconArrowRight} className={cx(styles.componentLinkArrow, layoutClassName)} {...{ href, dataX, label, target }} />
}

export function ButtonLinkArrowReversed({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase icon={iconArrowRight} className={cx(styles.componentLinkArrowReversed, layoutClassName)} {...{ href, dataX, label, target }} />
}

export function ButtonLinkRed({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkRed, layoutClassName)} {... { href, dataX, label, target }} />
}

export function ButtonLinkWhite({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkWhite, layoutClassName)} {... { href, dataX, label, target }} />
}

export function ButtonLinkGhost({ href, dataX, label, target = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkGhost, layoutClassName)} {... { href, dataX, label, target }} />
}

function ButtonLinkBase({ href, dataX, className, label, icon = undefined }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a
      data-x={dataX}
      aria-label={label}
      className={cx(styles.componentLinkBase, className)}
      {...{ href, rel, target }}
    >
      {label && <LabelUnderline layoutClassName={styles.labelLayout} {...{ label }} />}
      {icon && <ButtonIconBox layoutClassName={styles.iconLayout} {...{ icon }} /> }
    </a>
  )
}

export function ButtonIconBox({ icon, layoutClassName }) {
  return (
    <div className={cx(styles.componentIconBox, layoutClassName)}>
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
    </div>
  )
}
