import { Icon } from '/features/buildingBlocks/Icon'

import styles from './ButtonIconCircle.css'

export function ButtonIconCircle({ dataX, ariaLabel, onClick, icon, layoutClassName, disabled = undefined }) {
  return (
    <button
      type='button'
      data-x={dataX}
      aria-label={ariaLabel}
      className={cx(styles.component, layoutClassName)}
      {...{ onClick, disabled }}
    >
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
    </button>
  )
}
