import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export { dayjs, unixTimeStampToFormattedDate }

function unixTimeStampToFormattedDate(timestamp) {
  return timestamp && dayjs.unix(timestamp).format('DD-MM-YYYY')
}
